import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';
import QuoteTools from 'voUtils/QuoteTools.js';

import {
	fireBooking
}
from 'voUtils/TagEx.js';

import {
	getAbTest
}
from 'voUtils/AbTest.js';

import {
	doPost
}
from 'voUtils/RestEx.js';
import {
	getVoucher
}
from 'voUtils/VoucherStore.js';
import {
	getUserInfo
}
from 'voUtils/TagEx.js';

import {
	getRootUrl
}
from 'voUtils/BrowserEx.js';

import {
	getAgioAmount
}
from 'voUtils/QuoteTools.js';

import {
	requireLibs
}
from 'voUtils/loader.js';


export default {
	voVueComponent: 'voffice-booking',
	voMsgCodes: [
		'schedule.prepayment',
		'schedule.rest',
		'schedule.total',
		'schedule.onsite',
		'email.invalid'
	],
	data: function () {
		var pd = VOFFICE.testmode ? {
			title: 'Frau',
			forename: 'Erika',
			surname: 'Mustermann',
			email: VOFFICE.testmode.email || 'mustermann@v-office.com',
			mobile: '01749342333',
			message: 'Testbuchung',

			mainAddress: {
				country: VOFFICE.guestcountry || 'DE',
				street: 'Am Berg',
				housenumber: '33',
				postalcode: '23730',
				city: 'Neustadt'
			}
		} : {
			mainAddress: {
				country: VOFFICE.guestcountry || 'DE',
			}
		};

		return {
			pd: pd,
			coupon: {},
			status: undefined,
			showPriceDetails: false,
			bookingResult: undefined,
			bookingMode: undefined,
			travelInsuranceResult: undefined,
			acceptTravelInsuranceTerms: false,
			acceptTravelInsuranceProductinfo: false,
			acceptTravelInsuranceDisclaimer: false,
			downloadTerms: false,
			downloadInfo: false,
			downloadDisclaimer: false,
			bookingisDisabled: false,
			insuranceAcceptMode: '',
			showInsuranceCheckboxes: true,
			showInsuranceBookingHint: false,
			showInsuranceButtonHint: false,
			showInsuranceCountryHint: false,
			error: undefined

		};
	},
	props: {
		action: String,
		quote: Object,
		bookingData: Object,
		unitId: Number,
		cancel: Function,
		travelInsurance: Object
	},
	created: function () {
		this.QuoteTools = QuoteTools;
		if (this.quote.selectedTravelInsurance) {
			var country = this.pd.mainAddress.country;
			if (country === 'DE') {
				this.showInsuranceCountryHint = false;
			} else {
				this.showInsuranceCountryHint = true;
			}
			if (VOFFICE.travelInsurance && VOFFICE.travelInsurance.settings.acceptMode == 'downloadPdf') {
				this.bookingisDisabled = true;
				this.showInsuranceCheckboxes = false;
				this.showInsuranceBookingHint = true;
			}
		}
	},

	methods: {
		checkEmail: function (e) {

			var email = e.target.value;

			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				$('#email-error').remove();
				$(":submit").prop('disabled', false);
				return true;
			} else {
				$(":submit").prop('disabled', true);
				$("#pd_input_email").after('<div id="email-error" style="color:white; background: red; font-size: 13px; padding: 3px 15px; margin-top:5px; border-radius: 5px">' + this.voMsg("email.invalid") + '</div>');
				return false;
			}
		},
		checkCountryForInsurance: function () {
			var country = this.pd.mainAddress.country;
			var bookedTravelInsurance;
			if (this.quote.selectedTravelInsurance) {
				if (country !== 'DE') {
					bookedTravelInsurance = true;
					this.bookingisDisabled = false;
					this.showInsuranceBookingHint = false;
					this.showInsuranceCountryHint = true;
				} else if (country === 'DE') {
					this.bookingisDisabled = true;
					this.showInsuranceBookingHint = true;
					this.showInsuranceDetails = true;
					this.showInsuranceCountryHint = false;
				}
			}
		},
		goBook: function () {

			if (this.pd.mainAddress.country !== 'DE') {
				delete this.quote.selectedTravelInsurance;
			}

			var req = {
				reservation: {
					customer: this.pd
				},
				action: this.action,
				rootUrl: getRootUrl(),
				bookings: [],
				travelInsurancePlan: this.quote.selectedTravelInsurance && this.quote.selectedTravelInsurance.planid,
				travelInsuranceAmount: this.quote.selectedTravelInsurance && this.quote.selectedTravelInsurance.planCost.amount

			};

			if (VOFFICE.travelInsurance && VOFFICE.travelInsurance.forceTravelInsuranceMode) {
				req.forceTravelInsuranceMode = VOFFICE.travelInsurance.forceTravelInsuranceMode;
			}

			if (VOFFICE.currency) {
				req.reservation.customer.currency = VOFFICE.currency;
			}

			var voucher = getVoucher();
			if (voucher) {
				req.voucherCode = voucher.code;
			}
			if (this.coupon.ok) {
				req.couponCode = this.coupon.code;
			}


			if (getAbTest()) {
				req.abtest = getAbTest();
			}

			if (VOFFICE.newsletter && VOFFICE.newsletter.def && this.pd.newsletter) {
				req.newsletter = VOFFICE.newsletter.def;
			}

			if (req.action === 'booking') {
				req.paymentMethods = VOFFICE.paymentMethods;
			}

			var ui = getUserInfo();
			if (ui) {
				req.ui = ui;
			}

			if (VOFFICE.bookingOptions) {
				Object.assign(req, VOFFICE.bookingOptions);
			}


			var b = Object.assign({
				unit: this.unitId
			}, this.bookingData);


			if (this.quote.oMandatory) {
				b.servicelines = [...this.quote.oMandatory];
				for (let sl of this.quote.mandatory) {
					if (sl.booked) {
						b.servicelines.push(sl);
					}
				}
			} else {
				b.servicelines = this.quote.mandatory;
			}

			var onsite = find(this.quote.onsite, {
				onsiteOptional: true
			});
			if (this.quote.onsite.length) {
				b.servicelines = this.quote.mandatory.concat(this.quote.onsite);
			}

			req.bookings = [b];

			this.error = undefined;
			this.status = 'booking';
			doPost('book', req)
				.then(res => {
					this.status = 'done';
					this.coupon = {};
					res.reservation.payNow = this.getPayNow(res.reservation.paymentSchedule);

					this.bookingResult = res.reservation;
					this.paymentMethods = res.paymentMethods;
					this.bookingMode = res.bookable;
					this.travelInsuranceResult = res.travelInsuranceResult ? res.travelInsuranceResult : null;

					fireBooking(req, res);
					window.scrollTo(0, 0);

				}).catch(e => {
					V.logWarn("book error", e);
					this.status = 'error';
					this.error = e;
				});

		},
		getPayNow: function (paymentSchedule) {
			var res = {};
			//_.each(paymentSchedule, function (val, key) {
			if (paymentSchedule) {
				for (let [key, val] of Object.entries(paymentSchedule)) {
					let ps = Object.assign({}, val);
					if (key === 'rest') {
						if (paymentSchedule.prepayment) {
							ps.amount += paymentSchedule.prepayment.amount;
						}
						key = 'total';
					}
					if (key !== 'onsite') {
						res[key] = ps;
					}

				}
			}
			return res;
		},
		hasTypeBooked: function (quote, type) {
			if (quote && quote.mandatory) {
				for (let sl of quote.mandatory) {
					if (sl.amount && sl.service.type === type) {
						return true;
					}
				}
			}
			return false;

		},
		preparePaypal: function (paypal, origAmount) {
			var res = Object.assign({}, paypal.button);
			var amount = QuoteTools.getAgioAmount(origAmount, paypal);
			var base = getRootUrl();

			res.amount = amount / 100;
			res.cancel_return = base + res.cancel_return;
			res.return = base + res.return;
			res.currency_code = VOFFICE.currency || 'EUR';
			res.custom = origAmount + ':' + amount;

			return res;
		},
		checkoutStripe: function (pm, r, amount) {

			var onStripeLoaded = requireLibs([
				"stripe.js",
			]);

			var req = {
				id: r.id,
				nr: r.nr,
				amount: amount,
				paymentMethodId: pm.id,
				rootUrl: getRootUrl()
			};

			doPost('checkoutPayment', req)
				.then(res => {
					onStripeLoaded(() => {
						var stripe = Stripe(pm.paymentTest ? 'pk_test_62sy8HrukNuTd7ko6lZcyJcv00CtNmm5oo' : 'pk_live_lHTr8iFxjPqtC0aYmkQYZuzZ00qtUAATYP', {
							stripeAccount: res.accountId
						});

						stripe.redirectToCheckout({
							// Make the id field from the Checkout Session creation API response
							// available to this file, so you can provide it as parameter here
							// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
							sessionId: res.sessionId
						}).then(function (result) {
							V.log("result", result);
							// If `redirectToCheckout` fails due to a browser or network
							// error, display the localized error message to your customer
							// using `result.error.message`.
							if (result.error) {
								this.error = {
									reasonText: result.error.message
								};
							}
						});
					});

				}).catch(e => {
					V.logWarn("checkout error", e);
					this.error = e;
				});



		},
		checkCoupon: function () {
			var coupon = this.coupon;
			coupon.checking = true;
			let total = QuoteTools.getTotal(this.quote, 'all');
			doPost('checkCoupon', coupon)
				.then(res => {
					res.cash = Math.min(total, res.available);
					Object.assign(coupon, res);
				}).catch(e => {
					V.logWarn("checkcoupon error", e);
					this.error = e;
				}).finally(e => {
					coupon.checking = false;
					this.$forceUpdate();
				});


		},
		insuranceGtc: function () {
			if (this.quote.selectedTravelInsurance) {
				window.open(this.quote.selectedTravelInsurance.termsConditionsUrl, 'terms');
				// doesnt work
				window.open(this.quote.selectedTravelInsurance.quoteDetailUrl, 'info');
				window.open(this.quote.selectedTravelInsurance.disclaimerUrl, 'disclaimer');
			}
		},
		consentInsurance: function (url, what) {
			var acceptMode = this.insuranceAcceptMode;
			if (VOFFICE.travelInsurance && VOFFICE.travelInsurance.settings.acceptMode) {
				acceptMode = VOFFICE.travelInsurance.settings.acceptMode;
				this.insuranceAcceptMode = acceptMode;
			}
			if (acceptMode == 'openPdf') {
				this.openPdf(url, what);

			} else if (acceptMode == 'downloadPdfDirekt') {
				this.downloadPdf(url, what);
			}

		},
		openPdf: function (url, tabName) {
			var handle = window.open(url, tabName);
			handle.blur();
			self.window.focus();
		},
		downloadPdf(targetUrl, fileName) {

			if (this.showInsuranceBookingHint) {

				if (fileName === 'Versicherungsbedingungen') {
					this.downloadTerms = true;
					$('#linkterms').removeClass('missing');
				} else if (fileName === 'Produktionformationen') {
					this.downloadInfo = true;
					$('#linkinfo').removeClass('missing');
				} else if (fileName === 'Widerrufsbelehrung') {
					this.downloadDisclaimer = true;
					$('#linkdisclaimer').removeClass('missing');
				}

				if (this.downloadTerms && this.downloadInfo && this.downloadDisclaimer) {
					this.bookingisDisabled = false;
					this.showInsuranceButtonHint = false;
					$('#urvhint').modal('hide');
				}
			}

			var url = 'https://ea.v-office.com/if/fetchInsurancePdf?pdf=' + encodeURI(targetUrl);
			var req = new XMLHttpRequest();

			var iPdf = document.getElementById('iPdf');
			if (iPdf) {
				iPdf.src = targetUrl;
				iPdf.hidden = false;
			}

			req.open("GET", url, true);
			req.responseType = "blob";
			req.onload = function () {
				console.log("load", req.response);
				//Convert the Byte Data to BLOB object.
				var blob = new Blob([req.response], {
					type: "application/octetstream"
				});
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob);
					return;
				}
				//Check the Browser type and download the File.
				var link = window.URL.createObjectURL(blob);

				var a = document.createElement('a');
				a.setAttribute('href', link);
				a.setAttribute('download', fileName + ".pdf");
				a.click();
				setTimeout(function () {
					// For Firefox it is necessary to delay revoking the ObjectURL
					window.URL.revokeObjectURL(link);
				}, 100);
			};
			req.send();
		},
		checkInsuranceDownload: function () {

			if (this.bookingisDisabled) {
				this.showInsuranceButtonHint = true;
				$('#urvhint').modal('show');
				if (!this.downloadTerms) {
					$('#linkterms').addClass('missing');
				}
				if (!this.downloadInfo) {
					$('#linkinfo').addClass('missing');
				}
				if (!this.downloadDisclaimer) {
					$('#linkdisclaimer').addClass('missing');
				}
			}

		}
	}
};